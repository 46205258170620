<template>
  <div>
    <Assistant_teacher_bar />
   <v-container fluid>
    <manageAssistantTeacherCommittee/>

   </v-container>
  </div>
</template>

<script>
import Assistant_teacher_bar from "../../../components/admin/assistant_teacher_bar.vue";
import manageAssistantTeacherCommittee from "../../../components/admin/manageAssistantTeacherCommittee.vue";
export default {
  name: "HRvecManageAssistantTeacherCommittee",
  data() {
    return {     
    };
  },
  async mounted() {
    
  },
  methods: {   
  },
  components: { Assistant_teacher_bar,manageAssistantTeacherCommittee }
};
</script>

<style lang="scss" scoped></style>
